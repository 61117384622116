define("ember-cp-validations/validations/warning-result-collection", ["exports", "ember-cp-validations/validations/result-collection", "ember-cp-validations/utils/cycle-breaker", "ember-cp-validations/utils/array"], function (_exports, _resultCollection, _cycleBreaker, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _resultCollection.default.extend({
    isValid: Ember.computed(() => true).readOnly(),
    isTruelyValid: Ember.computed.not('isValidating').readOnly(),
    messages: Ember.computed(() => []).readOnly(),
    errors: Ember.computed(() => []).readOnly(),
    warningMessages: Ember.computed('content.@each.{messages,warningMessages}', (0, _cycleBreaker.default)(function () {
      return (0, _array.uniq)((0, _array.compact)((0, _array.flatten)([this.getEach('messages'), this.getEach('warningMessages')])));
    })).readOnly(),
    warnings: Ember.computed('attribute', 'content.@each.{errors,warnings}', (0, _cycleBreaker.default)(function () {
      return this._computeErrorCollection((0, _array.flatten)([this.getEach('errors'), this.getEach('warnings')]));
    })).readOnly()
  });
});
define("ember-cp-validations/decorators/has-validations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasValidations = hasValidations;
  /**
   * Decorator that allows you to mixin validations when using es6 style model classes
   *
   * Usage:
   *
   * import { hasValidations } from 'ember-cp-validations';
   *
   * @hasValidations(Validations)
   * export default class YourModel extends Model {
   *    @attr('string') name
   * };
   *
   *
   * @param validations
   * @returns {decorator}
   */

  function hasValidations(validations) {
    return desc => {
      return {
        ...desc,
        finisher(klass) {
          klass.prototype.reopen(validations);
          return klass;
        }
      };
    };
  }
});
define("ember-cp-validations/utils/deep-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = deepSet;
  function deepSet(obj, path, value, useEmberObject = false, delimiter = '.') {
    let keyPath = path.split(delimiter);
    let lastKeyIndex = keyPath.length - 1;
    let currObj = obj;

    // Iterate over each key in the path (minus the last one which is the property to be assigned)
    for (let i = 0; i < lastKeyIndex; ++i) {
      let key = keyPath[i];

      // Create a new object if it doesnt exist
      if (Ember.isNone(Ember.get(currObj, key))) {
        Ember.set(currObj, key, useEmberObject ? Ember.Object.create() : {});
      }
      currObj = Ember.get(currObj, key);
    }
    if (value instanceof Ember.ComputedProperty) {
      Ember.defineProperty(currObj, keyPath[lastKeyIndex], value);
    } else {
      Ember.set(currObj, keyPath[lastKeyIndex], value);
    }
  }
});
define("ember-modifier/-private/class/modifier-manager", ["exports", "ember-modifier/-private/class/modifier"], function (_exports, _modifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ClassBasedModifierManager {
    constructor() {
      _defineProperty(this, "capabilities", Ember._modifierManagerCapabilities('3.13'));
    }
    createModifier(factory, args) {
      let {
        owner,
        class: modifier
      } = factory;
      return new modifier(owner, args);
    }
    installModifier(instance, element) {
      instance.element = element;
      instance.didReceiveArguments();
      instance.didInstall();
    }
    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      Ember.set(instance, 'args', args);
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }
    destroyModifier(instance) {
      instance.willRemove();
      instance.element = null;
      if (instance[_modifier.DESTROYING]) {
        return;
      }
      let meta = Ember.meta(instance);
      meta.setSourceDestroying();
      instance[_modifier.DESTROYING] = true;
      Ember.run.schedule('actions', instance, instance.willDestroy);
      Ember.run.schedule('destroy', undefined, scheduleDestroy, instance, meta);
    }
  }
  function scheduleDestroy(modifier, meta) {
    if (modifier[_modifier.DESTROYED]) {
      return;
    }
    Ember.destroy(modifier);
    meta.setSourceDestroyed();
    modifier[_modifier.DESTROYED] = true;
  }
  var _default = _exports.default = new ClassBasedModifierManager();
});